export function changePriceFormat(price) {
  if (typeof price === 'string') {
    price = parseFloat(price);
  }
  if (price === null) {
    price = 0;
  }
  price = price.toFixed(2);
  let formated = price.toString().replace('.', ',');
  return formated;
}

export function shortProductName(name) {
  let response = '';
  // Get first 4 words
  if (name.split(' ').length > 4) {
    for (let i = 0; i < 4; i++) {
      response += name.split(' ')[i] + ' ';
    }
    if (name.indexOf('EXTRA') > -1) {
      response += '+ ' + name.split('EXTRA:')[1].split(']')[0];
    }
  } else {
    response = name;
  }

  return response;
}

export function serializeForm(form) {
  const formData = new FormData(form).entries();
  const json = Object.assign(...Array.from(formData, ([x, y]) => ({[x]: y})));
  return json;
}

export function headerWithToken() {
  return {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  }
}

export function parseJwt() {
  const token = localStorage.getItem('token');
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export function validateEmail(email) {
  if (email.indexOf('@') > -1 && email.length > 5) {
    return true;
  }
  return {
    error: 'E-mail nie je zadaný v správnom tvare.'
  };
}

export function validatePassword(password) {
  if (password.length > 6) {
    return true;
  }
  return {
    error: 'Heslo musí obsahovať aspoň 6 znakov.'
  };

}

export function validateFirstName(name) {
  if (name.length > 3) {
    return true;
  }
  return {
    error: 'Meno musí obsahovať aspoň 3 znaky.'
  };
}

export function validateLastName(name) {
  if (name.length > 3) {
    return true;
  }
  return {
    error: 'Priezvisko musí obsahovať aspoň 3 znaky.'
  };
}

export function validateTelephone(value) {
  if (value.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)) {
    return true;
  }
  return {
    error: 'Tel. číslo nie je v správnom tvare.'
  };
}

export function sort(items) {
  return items.sort((a, b) => a.id - b.id)
}

export function calculateClosedDoorsAlert() {
  const actualDay = new Date().getDay(); // return index -- from Sunday
  const actualHours = new Date().getHours();
  let response = {
    message: '',
    open: true
  };

  // Logic for Christmas (vianocne trhy)
  const day = replaceDayMonth(new Date().getDate());
  const month = replaceDayMonth(new Date().getMonth() + 1);
  const year = new Date().getFullYear();
  const closedDays = [
    '2020-01-01'
  ];
  for (let i = 0; i < closedDays.length; i++) {
    if (new Date(closedDays[i]).getTime() === new Date(year + '-' + month + '-' + day).getTime()) {
      response = {
        message: 'Vážení zákazníci, ďakujeme Vám za Vašu priazeň v roku 2019 a tešíme sa na rok 2020, v ktorom Vám budeme i naďalej napľňať Vaše brušká. Objednávky prijímame od 2.1.2020, 10:00 hod.',
        open: false
      };
      return response;
    }
  }

  switch (actualDay) {
    // Friday or Saturday
    case 5:
      // 10:00 - 20:00
      if (actualHours >= 10 && actualHours < 18) {
        // opening
        response.message = 'Máme otvorené. Môžeš si objednať.';
      } else {
        // closed

        response.message = 'Dnes už máme zatvorené. Objednať si môžeš opäť v pondelok od 10:00.';
        if (actualHours < 10) {
          response.message = 'Aktuálne máme zatvorené. Objednať si môžeš už o 10:00.';
        }
        response.open = false;
      }
      break;
    // Sunday
    case 0:
      // on Sunday is closed
      response.message = 'Dnes oddychujeme. Objednať si môžeš opäť zajtra od 10:00.';
      response.open = false;
      break;
    // Saturday
    case 6:
      // on Sunday is closed
      response.message = 'Dnes oddychujeme. Objednať si môžeš opäť v pondelok od 10:00.';
      response.open = false;
      break;
    default:
      // 10:00 - 18:00
      if (actualHours >= 10 && actualHours < 18) {
        // opening
        response.message = 'Máme otvorené. Môžeš si objednať.';
      } else {
        // closed
        response.message = 'Dnes už máme zatvorené. Objednať si môžeš opäť zajtra od 10:00.';
        if (actualHours < 10) {
          response.message = 'Aktuálne máme zatvorené. Objednať si môžeš už o 10:00.';
        }
        response.open = false;
      }
      break;
  }

  return response;

}

export function getDeliveryFee(city) {
  let fee = 0;
  switch (city) {
    case 'Spišská Nová Ves':
    case 'Pod Tepličkou':
    case 'Smižany':
    case 'Ferčekovce':
      fee = 1;
      break;
    case 'iné':
      fee = 2;
      break;
    default:
      fee = 1.5;
      break;
  }
  return fee;
}

function replaceDayMonth(s) {
  let res = s.toString();
  if (res.length === 1) {
    res = '0' + res;
  }
  return res;
}