import React, {useState, useEffect} from 'react';

import ProductListItem from './product-list-item';
import {connect} from 'react-redux';
import {cartItemsWithQuantities} from '../cart';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import styled from 'styled-components';
import * as helperConstants from '../../helpers/constants';
import {products} from '../../services/api/products';
import data from '../../data/products';
import {productsEndpoint} from '../../endpoints';

const CategoryName = styled.div`
    background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 120px;
  text-align: center;
  color: ${helperConstants.white};
  margin-bottom: 20px;
  h3 {
    height: 100%;
    padding-top: 45px;
    background: rgba(0, 0, 0, .5);
    @media screen and (max-width: 991px) {
        padding: 30px 15px 0;
    }
  }
`;


function ProductListing(props) {
    const [products, setProducts] = useState([]);
    useEffect(() => {
            async function fetchData() {
                const res = await fetch(productsEndpoint);
                res
                    .json()
                    .then(res => setProducts(res))
            }

            if (products.length === 0)
                fetchData();
        }
    );

    return <Row className='product-listing'>
        {
            (products.length > 0) ?
              products.filter(p => p.category.toLowerCase() === 'polievky').map((product, key) => {
                  return product.id !== 6 && (
                    <Col lg={12} className="category" key={key}>
                        <CategoryName style={{backgroundImage: 'url(' + product.image + ')'}}>
                            <h3>{product.category.toUpperCase()}</h3>
                        </CategoryName>
                        <Row>
                            {
                                product.products.map(p =>
                                  <ProductListItem
                                    product={p}
                                    extra={product.extras}
                                    addToCart={props.addToCart}
                                    removeFromCart={props.removeFromCart}
                                    cartItem={props.cart.filter(cartItem => cartItem.id === p.id)[0]}
                                  />
                                )
                            }
                        </Row>
                    </Col>
                  );
              }) : ''

        }
        {
            (products.length > 0 && products.some(p => p.id === 6) && (new Date() >= new Date('2020-06-29')) && (
                <Col lg={12} className="category">
                    <CategoryName style={
                        {
                            backgroundImage: 'url(' + products[3].image + ')',
                            backgroundAttachment: 'initial'
                        }
                    }>
                        <h3>{products[3].category.toUpperCase()}</h3>
                    </CategoryName>
                    <Row>
                        {
                            products[3].products.map(p =>
                                <ProductListItem
                                    product={p}
                                    extra={products[3].extras}
                                    addToCart={props.addToCart}
                                    removeFromCart={props.removeFromCart}
                                    cartItem={props.cart.filter(cartItem => cartItem.id === p.id)[0]}
                                />
                            )
                        }
                    </Row>
                </Col>
            ))
        }
        {
            (products.length > 0) ?
                products.filter(p => p.category.toLowerCase() !== 'polievky').map((product, key) => {
                    return product.id !== 6 && (
                        <Col lg={12} className="category" key={key}>
                            <CategoryName style={{backgroundImage: 'url(' + product.image + ')'}}>
                                <h3>{product.category.toUpperCase()}</h3>
                            </CategoryName>
                            <Row>
                                {
                                    product.products.map(p =>
                                        <ProductListItem
                                            product={p}
                                            extra={product.extras}
                                            addToCart={props.addToCart}
                                            removeFromCart={props.removeFromCart}
                                            cartItem={props.cart.filter(cartItem => cartItem.id === p.id)[0]}
                                        />
                                    )
                                }
                            </Row>
                        </Col>
                    );
                }) : ''

        }
    </Row>;
}

function mapStateToProps(state) {
    return {
        cart: state.cart
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addToCart: (item, extra) => {
            const extraPrice = (extra.length === 0) ? 0 : extra.price;
            const extraName = (extra.length === 0) ? '' : ' [ EXTRA: ' + extra.name + ' ]';

            item.resultPrice = item.price + extraPrice;
            item.resultName = item.name + extraName;
            item.extra = extra;
            dispatch({type: 'ADD', payload: item})
        },
        removeFromCart: (item) => {
            dispatch({type: 'REMOVE', payload: item})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductListing);