import React, {useState} from 'react';
import AddButton from './add-btn';
import RemoveButton from './remove-btn';
import Col from 'reactstrap/es/Col';
import {changePriceFormat} from '../../services/helpers';
import Row from 'reactstrap/es/Row';

export default function ProductListItem(props) {
    const [extra, setExtra] = useState([]);
    return <Col lg={12} md={12} xs={12} className='product-list-item'>
        <Row>
            <Col lg={9}>
                <Row>
                    <Col lg={2} md={2} sm={3} xs={3}>
                        <div
                            className={(changePriceFormat(props.product.price).length > 4) ? 'productPrice smaller' : 'productPrice'}>{changePriceFormat(props.product.price)} €
                        </div>
                    </Col>
                    <Col lg={10} md={10} sm={9} xs={9}>
                        {(props.product.id < 37 || props.product.id > 81) && (
                            <p className={(props.extra.length > 0) ? 'productName withExtra' : 'productName'}
                               dangerouslySetInnerHTML={{__html: (props.product.weight) ?
                                       ((props.product.id > 22) ? props.product.name + ' (' + props.product.weight + 'ml)' : props.product.name + ' (' + props.product.weight + 'g)') : props.product.name}}>
                            </p>
                        )}
                        {props.product.id >= 37 && props.product.id < 82 && (
                            <p className='productName withExtra d-block' dangerouslySetInnerHTML={{__html: `<span style="text-decoration: underline">${props.product.name} (${props.product.weight}g)</span><br /><span>${props.product.description}</span>`}} />
                        )}
                        {
                            (props.product.allergens.length > 0) ? <div>
                                    <button className="alergens">Alergény:&nbsp;
                                        {
                                            props.product.allergens.map((a, key) =>
                                                (key > 0) ? ', ' + a.global_identifier : a.global_identifier
                                            )
                                        }
                                    </button>
                                    <span className="hiddenAlergens">
                                    <table>
                                        <tbody>
                                    {
                                        props.product.allergens.map((a, key) =>
                                            <tr key={key}>
                                                <td valign="top">{a.global_identifier}</td>
                                                <td valign="top"> - </td>
                                                <td valign="top"><b>{a.name}</b> {a.description}</td>
                                            </tr>
                                        )
                                    }
                                        </tbody>
                                    </table>
                                </span>
                                </div>
                                : ''
                        }
                        {
                            (props.extra.length > 0) ?
                                props.extra.map(e =>
                                    <label className="extraLabel" htmlFor={'extra' + e.id + '_' + props.product.id}>
                                        <input type="checkbox" id={'extra' + e.id + '_' + props.product.id}
                                               onChange={(el) => (el.target.checked) ? setExtra(e) : setExtra([])}/><span/>
                                        {e.name} <b
                                        dangerouslySetInnerHTML={{__html: (e.weight) ? changePriceFormat(e.price) + '€/<sub>' + e.weight + 'g' + '</sub>' : changePriceFormat(e.price) + '€'}}/></label>
                                ) : ''
                        }
                    </Col>
                </Row>
                {/*<div>{props.product.description}</div>*/}
            </Col>
            <Col lg={3}>
                <div>
                    <AddButton
                        cartItem={props.cartItem}
                        extra={extra}
                        product={props.product}
                        addToCart={props.addToCart}
                    />

                </div>
            </Col>
        </Row>
    </Col>;
}

